import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { theme } from "../core/theme";
import { BackButton } from "../components";

export default function Header({ navigation, title }: any) {
  return (
    <View style={styles.header}>
      <BackButton goBack={navigation.goBack} />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    color: theme.colors.primary,
    height: 44,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F7FA",
  },
  title: {
    fontSize: 17,
    fontWeight: "bold",
  },
});
