import React from "react";
import { createTheme, ThemeProvider } from "@rneui/themed";
import HomeLayout from "./src/screens/homeLayout";
import biHomeLayout from "./src/screens/biHomeLayout";
import { View, StyleSheet, AppRegistry } from "react-native";
import ProfileLayout from "./src/screens/profileLayout";
import { NavigationContainer } from "@react-navigation/native";
//@ts-ignore
// import { createStackNavigator } from "@react-navigation/stack";
//@ts-ignore
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { registerRootComponent } from "expo";

const Tab = createBottomTabNavigator();
// const Stack = createStackNavigator();

const theme = createTheme({
  lightColors: {},
  darkColors: {},
});

export default function App() {
  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
      }}
    >
      <View
        style={{
          width: "100%",
          maxWidth: 500,
          height: "100%",
          margin: "auto",
          backgroundColor: "rgb(245,247,250)",
        }}
      >
        <ThemeProvider theme={theme}>
          <NavigationContainer>
            <Tab.Navigator>
              <Tab.Screen
                name="HomeLayout"
                component={HomeLayout}
                options={{
                  tabBarLabel: ({ focused, color }) => (
                    <span
                      style={{ color: focused ? "rgb(148,88,198)" : color }}
                    >
                      首页
                    </span>
                  ),
                  headerShown: false,
                  tabBarIcon: ({ focused, color, size }: any) => (
                    <MaterialCommunityIcons
                      name="home"
                      color={focused ? "rgb(148,88,198)" : color}
                      size={size}
                    />
                  ),
                }}
              />
               <Tab.Screen
                name="biHome"
                component={biHomeLayout}
                options={{
                  tabBarLabel: ({ focused, color }) => (
                    <span
                      style={{ color: focused ? "rgb(148,88,198)" : color }}
                    >
                      潜力币
                    </span>
                  ),
                  headerShown: false,
                  tabBarIcon: ({ focused, color, size }: any) => (
                    <MaterialCommunityIcons
                      name="archive"
                      color={focused ? "rgb(148,88,198)" : color}
                      size={size}
                    />
                  ),
                }}
              />
              <Tab.Screen
                name="ProfileLayout"
                component={ProfileLayout}
                options={{
                  tabBarLabel: ({ focused, color }) => (
                    <span
                      style={{ color: focused ? "rgb(148,88,198)" : color }}
                    >
                      我的
                    </span>
                  ),
                  headerShown: false,
                  tabBarIcon: ({ focused, color, size }: any) => (
                    <MaterialCommunityIcons
                      name="account"
                      color={focused ? "rgb(148,88,198)" : color}
                      size={size}
                    />
                  ),
                }}
              />
            </Tab.Navigator>
          </NavigationContainer>
        </ThemeProvider>
      </View>
    </View>
  );
}

// 注册根组件
// registerRootComponent(App);
// 如果你之前使用了ReactDOM.render来渲染根组件，可以将其移除

// // 启动应用
// AppRegistry.runApplication("Ai-signarl-app", {
//   rootTag: document.getElementById("root"),
// });
