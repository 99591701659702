import React from "react";
import { Text, TouchableOpacity, StyleSheet } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

export default function ArrowButton({
  onPress,
  size,
  left,
  disabled,
}: {
  onPress: any;
  size?: number;
  left?: boolean;
  disabled?: boolean;
}) {
  return (
    <TouchableOpacity onPress={!disabled && onPress}>
      <Text
        style={{
          fontWeight: "bold",
          transform: [{ rotate: left ? "270deg" : "90deg" }],
        }}
      >
        <MaterialCommunityIcons
          name="apple-keyboard-control"
          color={disabled ? "#EEE" : "#616F85"}
          size={size || 12}
        />
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  process_container: {
    width: 54,
    height: 6,
    backgroundColor: "#E8E8E8",
    borderRadius: 3,
    margin: 6,
    marginLeft: 0,
  },
});
