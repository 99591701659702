import { View, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { Text, Card, Button, Icon, ListItem } from "@rneui/themed";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { Logo, Title, CandleStick, TopHeader } from "../../components";
import React, { useState, useEffect } from "react";
import { getSignalList } from "../api";
import Wiki from "../wiki";
import BiSignalItem from "./BiSignalItem";

export default function Details({ navigation, route }: any) {
  const { params } = route;
  const { signal } = params;
  const [signalList, setSignalList] = useState([]);
  const [signalListData, setSignalListData] = useState<any>({});

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const putData = {
      modelId: signal?.modelId,
    };
    fetchData(putData);
  }, []);

  const fetchData = async (params: any) => {
    setLoading(true);
    const { page, pageSize } = pagination;
    const result: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      ...params,
    });
    if (result && result.status === "success") {
      const { list, total } = result.data;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
      });
      setSignalList(list);
      setSignalListData(result.data);
      setPagination({
        pageNum: params.page || page,
        pageSize: params.pageSize || pageSize,
        total,
      });
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <TopHeader navigation={navigation} title={signal?.contract} />
      <Logo></Logo>

      <ScrollView>
        <CandleStick
          symbol={signal?.contract}
          exchangeName={signal?.exchangeName}
          isBI={true}
          dim={signal.duration}
        ></CandleStick>
        <Title title="趋势追踪精选信号"></Title>
        <BiSignalItem navigation={navigation} signal={signal} />
        <View></View>
        <Wiki   symbol={signal.contract} navigation={navigation} wikiNo={signal.wikiNo} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  tdItem: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
});
