
export default function getChartOption({originData, pointData, lineData,name}) {
    const data = JSON.parse(JSON.stringify(originData));
    data.forEach((item) => {
      const fifthElement = item.splice(4, 1)[0]; // 移除第五个元素并保存
      item.splice(2, 0, fifthElement);
    });
    var option;
  
    const upColor = "#0ECB81";
    const upBorderColor = "#0ECB81";
    const downColor = "RGB(240,70,100)";
    const downBorderColor = "RGB(240,70,100)";
    // Each item: open，close，lowest，highest
    const data0 = splitData(data);
    function splitData(rawData) {
      const categoryData = [];
      const values = [];
      for (var i = 0; i < rawData.length; i++) {
        categoryData.push(rawData[i].splice(0, 1)[0]);
        values.push(rawData[i]);
      }
      return {
        categoryData: categoryData,
        values: values,
      };
    }
    function calculateMA(dayCount) {
      var result = [];
      for (var i = 0, len = data0.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push("-");
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum += +data0.values[i - j][1];
        }
        result.push(sum / dayCount);
      }
      return result;
    }
    let lineParams = [
      {
        name: "min line on close",
        type: "min",
        valueDim: "close",
      },
      {
        name: "max line on close",
        type: "max",
        valueDim: "close",
      },
    ];
  
    if (lineData) {
      lineParams.push(lineData(true));
    }
    option = {
      // title: {
      //   text: "行情",
      //   left: 0,
      // },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      legend: {
        data: ["", "MA5", "MA10", "MA20", "MA30"],
      },
      grid: {
        left: "10%",
        right: "10%",
        bottom: "15%",
      },
      xAxis: {
        type: "category",
        data: data0.categoryData,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: "dataMin",
        max: "dataMax",
        
      },
      toolbox: {
        feature: {
          // dataZoom: {
          //   yAxisIndex: false,
          // },
          brush: {
            type: [""],
          },
        },
      },
      brush: {
        xAxisIndex: "all",
        brushLink: "all",
        outOfBrush: {
          colorAlpha: 0.1,
        },
      },
      yAxis: {
        scale: true,
        splitArea: {
          show: true,
        },
        axisLabel:{
          fontSize: 10
        }
      },
      dataZoom: [
        {
          type: "inside",
          start: 30,
          end: 100,
        },
        {
          show: true,
          type: "slider",
          top: "90%",
          start: 30,
          end: 100,
        },
      ],
      series: [
        {
          name: name||"",
          type: "candlestick",
          data: data0.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor,
          },
          markPoint: {
            symbol: "pin",
            symbolSize:25,
            label: {
              show: true,
              silent: true,
              //标签文字
              formatter: function (param) {
                return param != null ? param.name : "";
              },
              color: "#fff",
              itemStyle:{
                symbolSize:20
              }
            },
            data: pointData || [
              {
                name: "Mark",
                coord: ["08/31 12:30", 1700],
                value: 1700,
                itemStyle: {
                  color: "#5577ff",
                },
              },
            ],
            tooltip: {
              formatter: function (param) {
                return param.name + "<br>" + (param.data.coord || "");
              },
            },
          },
          markLine: {
            symbol: ["none", "none"],
            data: lineParams,
          },
          markArea: {
            data: lineData ? [lineData()] : null,
            label: {
              show: false,
            },
          },
        },
        // {
        //   name: "MA5",
        //   type: "line",
        //   data: calculateMA(5),
        //   smooth: true,
        //   lineStyle: {
        //     opacity: 0.5,
        //   },
        //   tooltip: {
        //     show: false,
        //   },
        // },
        // {
        //   name: "MA10",
        //   type: "line",
        //   data: calculateMA(10),
        //   smooth: true,
        //   lineStyle: {
        //     opacity: 0.5,
        //   },
        //   tooltip: {
        //     show: false,
        //   },
        // },
        // {
        //   name: "MA20",
        //   type: "line",
        //   data: calculateMA(20),
        //   smooth: true,
        //   lineStyle: {
        //     opacity: 0.5,
        //   },
        //   tooltip: {
        //     show: false,
        //   },
        // },
        // {
        //   name: "MA30",
        //   type: "line",
        //   data: calculateMA(30),
        //   smooth: true,
        //   lineStyle: {
        //     opacity: 0.5,
        //   },
        //   tooltip: {
        //     show: false,
        //   },
        // },
      ],
    };
  
    // if (option && typeof option === "object") {
    //   myChart.setOption(option);
    // }
    return option
  }
  
  /*
   * Licensed to the Apache Software Foundation (ASF) under one
   * or more contributor license agreements.  See the NOTICE file
   * distributed with this work for additional information
   * regarding copyright ownership.  The ASF licenses this file
   * to you under the Apache License, Version 2.0 (the
   * "License"); you may not use this file except in compliance
   * with the License.  You may obtain a copy of the License at
   *
   *   http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing,
   * software distributed under the License is distributed on an
   * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
   * KIND, either express or implied.  See the License for the
   * specific language governing permissions and limitations
   * under the License.
   */
  