import { View, Text, StyleSheet, Image } from "react-native";

export default function Logo() {
  return (
    <View style={styles.container}>
      <Image
        source={require("../../assets/logo.svg")}
        style={{ width: 133, height: 26, margin: 18, marginBottom: 10 }}
      />
      <View style={styles.textContainer}>
        <Text style={styles.text}>加密市场的AI预测器</Text>
        <Text style={styles.value}>AI Forecaster For Crypto Market</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  textContainer: {
    height: 26,
    margin: 18,
    marginBottom: 10,
  },
  text: {
    letterSpacing: 5,
  },
  value: {
    color: "#323233",
    fontSize: 12,
    lineHeight: 20,
  },
});
