import {
  View,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import { Text, Card, Button, Icon, ListItem } from "@rneui/themed";
import CandleStick from "../../components/CandleStick";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { useRequest } from "ahooks";
import { fnDateLine, getAddDayDateLine, fnMTime } from "../../helpers/day";
import HTMLView from "react-native-htmlview";
import {
  Process,
  ModelTypeName,
  Logo,
  Title,
  ArrowButton,
  Menu,
  WeekCalendar,
} from "../../components";
import React, { useState, useEffect } from "react";

import { getBiEvent, getBiWiki, getNewFlash, getNewFlashDetail } from "../api";

// onPress={() => navigation.navigate("Details")}
interface Column {
  title: string;
  dataIndex: string;
  key: string;
  width?: any;
  render?: any;
  justifyContent?: any;
  alignItems?: any;
}

export default function Details({ navigation, wikiNo, symbol }: any) {
  const [selectMenu, setSelectMenu] = useState(0);
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState([]);
  const [news, setNews] = useState([]);


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [selectMenu, date]);

  async function fetchData() {
    switch (selectMenu) {
      case 1:
        initEvent();
        break;

      case 2:
        runGetNewFlash({
          current: 1,
          size: 20,
        });
        break;
      case 3:
        break;
      default:
        initEvent();
        runGetNewFlash({
          current: 1,
          size: 20,
        });
        break;
    }
  }

  async function initEvent() {
    getBiEventList({
      endDate: getAddDayDateLine(date, "d", 7),
      startDate: fnDateLine(date, "d"),
      current: 1,
      size: 1000,
      symboleList: [symbol],
    });
  }

  const { run: getBiEventList } = useRequest(getBiEvent, {
    manual: true,
    onSuccess(result: any, params) {
      if (result && result.data) {
        setEvent(result.data);
      }
    },
  });

  const { run: runGetNewFlash } = useRequest(getNewFlash, {
    manual: true,
    onSuccess(result: any, params) {
      if (result && result.data) {
        setNews(result?.data?.records);
      }
    },
  });

  const {
    data: wiki,
    error: getBiWikiError,
    loading: getBiWikiLoading,
  } = useRequest(getBiWiki, {
    defaultParams: [
      {
        id: wikiNo,
      },
    ],
  });

  const menus = [
    {
      label: "美国指标",
      title: "信号币种影响政策",
      key: 1,
    },
    {
      label: "币种快讯",
      title: "信号币种快讯",
      key: 2,
    },
    {
      label: "百科知识",
      title: "信号币种百科知识",
      key: 3,
    },
  ];
  // const wikiOption = [
  //   { key: "", label: "24H成交额" },
  //   { key: "", label: "流通市值" },
  //   { key: "", label: "流通供应量" },
  //   { key: "", label: "占全球总市值" },
  //   { key: "", label: "流通率" },
  //   { key: "", label: "换手率" },
  //   { key: "", label: "发行价格" },
  //   { key: "", label: "发行总量" },
  //   { key: "", label: "核心算法" },
  //   { key: "", label: "发行时间" },
  // ];

  return (
    <View>
      <Menu
        menus={menus}
        selectMenu={selectMenu}
        HandleMenu={(menu: any) => setSelectMenu(menu.key)}
      ></Menu>
      {selectMenu !== 3 && (
        <SafeAreaView>
          <WeekCalendar
            date={date}
            onChange={(newDate: any) => setDate(newDate)}
          />
        </SafeAreaView>
      )}
      {(selectMenu === 0 || selectMenu === 1) && (
        <Title title="信号币种影响政策"></Title>
      )}
      {(selectMenu === 0 || selectMenu === 1) &&
        event.map((item: any) => (
          <View key={item.id} style={styles.listItem}>
            <View style={styles.row}>
              <Text style={styles.name}>{item.name}</Text>
              <Text style={styles.tag}>{item.tag}</Text>
              <Text style={styles.detail}>{fnMTime(item.time)}</Text>
            </View>
            <Text style={styles.title}>{item.title}</Text>
            <View style={styles.row}>
              {item.currencyList.map((currency: any) => (
                <Text style={styles.symbol} key={currency.symbol}>
                  {currency.symbol} &nbsp;
                  <Text
                    style={{
                      color:
                        currency.priceChangeDay < 0 ? "#E53A4B" : "#01C781",
                    }}
                  >
                    {currency.priceChangeDay > 0 ? "+" : "-"}
                    {(currency.priceChangeDay * 100).toFixed(2)}%
                  </Text>
                  &nbsp;
                  {`($${currency.price})`}
                </Text>
              ))}
            </View>
            <Text style={styles.detail}>{item.detail}</Text>
          </View>
        ))}
      {(selectMenu === 0 || selectMenu === 2) && (
        <Title title="信号币种快讯"></Title>
      )}

      {(selectMenu === 0 || selectMenu === 2) &&
        news &&
        news.map((item: any) => (
          <View key={item.id} style={styles.listItem}>
            <View style={styles.row}>
              <Text style={styles.detail}>{fnMTime(item.gmtUpdated)}</Text>
              <Text style={styles.title}>{item.author}</Text>
            </View>
            <Text style={styles.name}>{item.title}</Text>
            <View style={styles.row}>
              {item.currencyLink.map((currency: any) => (
                <Text style={styles.symbol} key={currency.symbol}>
                  {currency.symbol} &nbsp;
                  <Text
                    style={{
                      color:
                        currency.priceChangeDay < 0 ? "#E53A4B" : "#01C781",
                    }}
                  >
                    {currency.priceChangeDay > 0 ? "+" : ""}
                    {(currency.priceChangeDay * 100).toFixed(2)}%
                  </Text>
                </Text>
              ))}
            </View>
            <Text style={styles.detail}>{item.description}</Text>
          </View>
        ))}
      {(selectMenu === 0 || selectMenu === 3) && (
        <Title title="信号币种百科知识"></Title>
      )}
      {(selectMenu === 0 || selectMenu === 3) && wiki?.data && (
        <View style={styles.listItem}>
          <View style={styles.row}>
            <Image
              source={{ uri: wiki?.data?.icon }}
              style={{ width: 40, height: 40, marginRight: 16 }}
            />
            <View>
              <View style={{ ...styles.row, marginBottom: 4 }}>
                <Text style={styles.name}>{wiki?.data?.symbol}</Text>
                <Text style={styles.detail}>
                  {wiki?.data?.wikiZhName + "," + wiki?.data?.wikiName}
                </Text>
              </View>
              <View style={styles.row}>
                {typeof wiki?.data?.tags === "string" &&
                  wiki?.data?.tags
                    .split(",")
                    .map((tag: string) => (
                      <Text style={styles.symbol}>#{tag}</Text>
                    ))}
              </View>
            </View>
          </View>
          <Text style={{ ...styles.name, marginBottom: 8, marginTop: 8 }}>
            简介
          </Text>
          <Text style={styles.detail}>{wiki?.data?.remarks}</Text>
          {wiki?.data.html && (
            <HTMLView value={wiki?.data.html} stylesheet={htmlstyle} />
          )}
        </View>
      )}
    </View>
  );
}

const htmlstyle = StyleSheet.create({
  h3: {
    fontSize: 14,
    color: "#323233",
    lineHeight: 19,
    fontWeight: "bold",
    marginBottom: 8,
    marginTop: 8,
  },
  p: {
    fontSize: 12,
    color: "#81858D",
    lineHeight: 18,
  },
});
const styles = StyleSheet.create({
  listItem: {
    width: "100%",
    fontFamily: "PingFangC-Medium",
    padding: "5%",
    backgroundColor: "#fff",
    borderBottomColor: "#F6F7FA",
    borderBottomWidth: 1,
    fontSize: 12,
  },
  row: { display: "flex", flexDirection: "row", marginBottom: 4 },
  name: {
    fontSize: 14,
    color: "#323233",
    lineHeight: 19,
    fontWeight: "bold",
    marginRight: 8,
  },
  title: {
    color: "#616F85",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 4,
  },
  detail: {
    color: "#81858D",
    fontSize: 12,
    lineHeight: 18,
    marginBottom: 4,
    marginRight: 8,
  },
  tag: {
    backgroundColor: "#EEEFFB",
    borderRadius: 2,
    color: "#3F4DDC",
    fontSize: 12,
    padding: 2,
    marginRight: 8,
  },
  symbol: {
    backgroundColor: "#F9F4E8",
    borderRadius: 2,
    color: "#C69A1A",
    fontSize: 12,
    padding: 2,
    marginRight: 8,
  },
});
