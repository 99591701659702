import { View, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { Text, Card, Button, Icon, ListItem } from "@rneui/themed";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Process,
  ModelTypeName,
  Logo,
  Title,
  CandleStick,
  TopHeader,
} from "../../components";
import React, { useState, useEffect } from "react";
import { getSignalList } from "../api";
import { fnTime } from "../../helpers/day";
import Wiki from "../wiki";

// onPress={() => navigation.navigate("Details")}
interface Column {
  title: string;
  dataIndex: string;
  key: string;
  width?: any;
  render?: any;
  justifyContent?: any;
  alignItems?: any;
}

const wikiNoList: any = {
  BTCUSDT: "9af27794-738e-47cf-899d-59f68d44ddab",
  ETHUSDT: "a1efa171-fe14-45b9-839e-0d40409f1892",
};

export default function Details({ navigation, route }: any) {
  const { params } = route;
  const { signal } = params;
  const [signalList, setSignalList] = useState([]);
  const [highSignalList, setHighSignalList] = useState([]);
  const [signalListData, setSignalListData] = useState<any>({});

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const putData = {
      modelId: signal?.modelId,
    };
    fetchData(putData);
  }, []);

  const fetchData = async (params: any) => {
    setLoading(true);
    const { page, pageSize } = pagination;
    const result: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      ...params,
    });
    if (result && result.status === "success") {
      const { list, total } = result.data;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
      });
      setSignalList(list);
      setSignalListData(result.data);
      setPagination({
        pageNum: params.page || page,
        pageSize: params.pageSize || pageSize,
        total,
      });
    }

    const highResult: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      min: 2,
      ...params,
    });
    if (highResult && highResult.status === "success") {
      const { list } = highResult.data;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
      });
      setHighSignalList(list);
    }
    setLoading(false);
  };

  const columns: Column[] = [
    {
      title: "币种",
      dataIndex: "symbol",
      key: "symbol",
      width: "20%",
    },
    {
      title: "触发时间",
      dataIndex: "generateTime",
      key: "generateTime",
      width: "20%",
      render: (record: any) => {
        return (
          <Text style={styles.bottomLabel}>{fnTime(record.generateTime)}</Text>
        );
      },
    },
    {
      title: "周期",
      dataIndex: "time",
      key: "time",
      width: "20%",
      render: (record: any) => {
        return (
          <Text style={styles.topLabel}>
            {`${record?.scoreDetail?.min_time} - ${record?.scoreDetail?.max_time}`}
          </Text>
        );
      },
    },
    {
      title: "深度",
      dataIndex: "deepth",
      key: "deepth",
      width: "20%",
      render: (record: any) => {
        return (
          <Text style={styles.topLabel}>
            {record.scoreType === 4
              ? `大于 ${record.scoreDetail.min_depth}`
              : `${record.scoreDetail.min_depth} - ${record.scoreDetail.max_depth}`}
          </Text>
        );
      },
    },
    {
      title: "信号强度",
      dataIndex: "type",
      key: "type",
      width: "20%",
      render: (record: any) => {
        return <ModelTypeName model_type_name={record.model_type_name} />;
      },
    },
    // {
    //   title: "剩余结束时间",
    //   dataIndex: "process_rate",
    //   key: "process_rate",
    //   width: "15%",
    //   render: (record: any) => {
    //     return <Process process_rate={record.process_rate} />;
    //   },
    // },
  ];

  const HandlePress = () =>
    navigation.navigate("Flist", { signal: params?.signal });

  const TableHeader = ({ columns }: { columns: Column[] }) => (
    <ListItem
      containerStyle={{
        height: 35,
        backgroundColor: "#FAF9FE",
      }}
    >
      <ListItem.Content>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
          }}
        >
          {columns.map((column: Column, index) => (
            <Text
              key={column.key}
              style={{
                fontSize: 10,
                lineHeight: 19,
                color: "#616F85",
                display: "flex",
                width: column.width || "10%",
                justifyContent: column.justifyContent || "center",
                alignItems: column.alignItems || "center",
              }}
            >
              {column.title}
            </Text>
          ))}
        </View>
      </ListItem.Content>
    </ListItem>
  );
  return (
    <View style={styles.container}>
      <TopHeader navigation={navigation} title={signal?.model_name} />
      <Logo></Logo>
      <ScrollView>
        <CandleStick
          symbol={signal?.symbol === "BTCUSDT" ? "BTC/USDT" : "ETH/USDT"}
        ></CandleStick>
        <Title
          title="最新空信号"
          subTitle="查看更多"
          HandlePress={HandlePress}
        ></Title>
        <TableHeader columns={columns} />
        {signalList.map((signal: any, index) => (
          <ListItem
            key={signal.generateTime}
            containerStyle={{
              height: 35,
              backgroundColor: index % 2 === 1 ? "#FAF9FE" : "#FFFFFF",
            }}
          >
            <ListItem.Content>
              <View style={styles.td}>
                {columns.map((column: any) => (
                  <View
                    key={signal.generateTime + column.key}
                    style={{
                      width: column.width,
                      display: "flex",
                      alignItems: column.alignItems || "center",
                      justifyContent: column.justifyContent || "center",
                    }}
                  >
                    {column.render ? (
                      column.render(signal)
                    ) : (
                      <Text style={styles.topLabel}> {signal[column.key]}</Text>
                    )}
                  </View>
                ))}
              </View>
            </ListItem.Content>
          </ListItem>
        ))}
        <Title
          title="最近高级别的空信号"
          subTitle="查看更多"
          HandlePress={HandlePress}
        ></Title>

        {highSignalList.map((signal: any, index) => (
          <ListItem
            key={signal.generateTime}
            containerStyle={{
              height: 35,
              backgroundColor: index % 2 === 1 ? "#FAF9FE" : "#FFFFFF",
            }}
          >
            <ListItem.Content>
              <View style={styles.td}>
                {columns.map((column: any) => (
                  <View
                    key={signal.generateTime + column.key}
                    style={{
                      width: column.width,
                      display: "flex",
                      alignItems: column.alignItems || "center",
                      justifyContent: column.justifyContent || "center",
                    }}
                  >
                    {column.render ? (
                      column.render(signal)
                    ) : (
                      <Text style={styles.topLabel}>
                        {" "}
                        {signal[column.key] || "-"}
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            </ListItem.Content>
          </ListItem>
        ))}
        <Wiki
          navigation={navigation}
          wikiNo={wikiNoList[signal.symbol] || ""}
          symbol={signal?.symbol === "BTCUSDT" ? "BTC/USDT" : "ETH/USDT"}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  td: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 35,
  },
  tdItem: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  tdItemLast: {
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
  },
  topLabel: {
    fontSize: 12,
    lineHeight: 19,
    color: "#323233",
  },
  bottomLabel: {
    fontSize: 10,
    lineHeight: 19,
    color: "#616F85",
  },
});
