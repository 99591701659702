import React, { useRef, useState, useEffect } from "react";
import * as echarts from "echarts/core";
import getChartOption from "./chart";
import { CandlestickChart, LineChart } from "echarts/charts";
import { Menu } from "../components";

import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  BrushComponent,
  MarkLineComponent,
  MarkPointComponent,
  MarkAreaComponent,
} from "echarts/components";
import { SVGRenderer, SkiaChart } from "@wuba/react-native-echarts";
import { View, ActivityIndicator } from "react-native";
import { fnTime } from "../helpers/day";
import { getSignalList, getBiKline, getTrendHistory } from "../screens/api";

echarts.use([
  SVGRenderer,
  CandlestickChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  LineChart,
  ToolboxComponent,
  BrushComponent,
  MarkLineComponent,
  MarkPointComponent,
  MarkAreaComponent,
]);
const scoreTypeName = ["弱", "中", "强", "超"];
const mins = 60 * 1000;
const kline_dim = [
  {
    key: "15m",
    label: "15分钟",
    timestamp: 15 * mins,
  },
  {
    key: "1H",
    label: "1小时",
    timestamp: 60 * mins,
  },
  {
    key: "4H",
    label: "4小时",
    timestamp: 4 * 60 * mins,
  },
  {
    key: "1D",
    label: "1天",
    timestamp: 24 * 60 * mins,
  },
];

interface Props {
  signalData?: any; //自带数据
  exchangeName?: string;
  dim?: string; //默认维度
  isBI?: boolean; //Bi
  start?: number;
  end?: number;
  symbol?: string; //币种
  modelId?: string; // btc eth必须传模型id
}
export default function App(props: Props) {
  const skiaRef = useRef<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [dim, setDim] = useState<string>(props.dim || "1H");
  const [signalData, setSignalData] = useState<any[]>(
    props.signalData ? props.signalData : []
  );
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState(props.symbol || "ETHUSDT");
  const timestamp = kline_dim.find((i) => i.key === dim)?.timestamp || 0;
  let now = new Date().getTime();
  let init_start = props.start || now - timestamp * 200;
  const [start, setStart] = useState(init_start);
  const [end, setEnd] = useState(props.end || new Date().getTime());

  const initChart = ({ originData, pointData, lineData }: any) => {
    let chart: any, option: any;
    if (skiaRef.current) {
      chart = echarts.init(skiaRef.current, "light", {
        renderer: "svg",
        width: 390,
        height: 320,
      });
      option = getChartOption({
        originData,
        pointData,
        lineData,
        name: props?.symbol,
      });
      chart.setOption(option);
    }
    return () => chart?.dispose();
  };

  useEffect(() => {
    if (!skiaRef.current) {
      return;
    }
    if (data && data.length > 0 && signalData && signalData.length > 0) {
      let pointData: any[] = [],
        lineData: any;
      signalData &&
        signalData.length > 0 &&
        signalData.forEach((signal: any) => {
          // markPoint
          if (props.isBI) {
            let findPoint = data.find(
              (i) =>
                Number(i[6]) <= Number(signal.timestamp) &&
                Number(i[6]) + timestamp >= Number(signal.timestamp)
            );
            if (findPoint && findPoint[0]) {
              pointData.push({
                name: !!signal.isUp ? "B" : "S",
                coord: [
                  findPoint[0],
                  !!signal.isUp ? findPoint[4] : findPoint[3],
                ],
                value: !!signal.isUp ? findPoint[4] : findPoint[3],
                itemStyle: {
                  color: !!signal.isUp ? "#01C781" : "#E53A4B",
                },
              });
            }
          } else {
            let findPoint = data.find(
              (i) =>
                Number(i[6]) <= Number(signal.generateTime) &&
                Number(i[6]) + timestamp >= Number(signal.generateTime)
            );
            if (findPoint && findPoint[0]) {
              const isExistSignal = pointData.find((i) => {
                return (
                  i.coord[0] === findPoint[0] && i.scoreType > signal.scoreType
                );
              });
              if (!isExistSignal) {
                pointData.push({
                  name: scoreTypeName[signal.scoreType - 1],
                  scoreType: signal.scoreType,
                  coord: [findPoint[0], findPoint[3]],
                  value: findPoint[3],
                  itemStyle: {
                    color: "#E53A4B",
                  },
                });
              }
            }
          }

          // markLine 仅在详情列表里面，并且仅标注第一条
          if (props.signalData && props.signalData[0]) {
            let checked_detail = props.signalData[0].checked_detail;
            let findLineStartPoint = data.find(
              (i) =>
                Number(i[6]) <= Number(checked_detail.retrace_point_start) &&
                Number(i[6]) + timestamp >=
                  Number(checked_detail.retrace_point_start)
            );
            let findLineEndPoint = data.find(
              (i) =>
                Number(i[6]) <= Number(checked_detail.retrace_point_end) &&
                Number(i[6]) + timestamp >=
                  Number(checked_detail.retrace_point_end)
            );
            const isLong =
              props.signalData[0].model_type_name.includes("多");
            debugger
            lineData = (show: boolean) => [
              {
                name: `${isLong ? "上升" : "下降"}${
                  checked_detail.max_retrace_span
                }U，${
                  (isLong && props.signalData[0].longChecked) ||
                  (!isLong && props.signalData[0].checked)
                    ? "抵达"
                    : "未抵达"
                }预测区间`,
                coord: [findLineStartPoint?.[0], checked_detail.retrace_start],
                value: checked_detail.retrace_start,
                symbol: "circle",
                symbolSize: 5,
                label: {
                  show: show,
                  formatter: function (param: any) {
                    return param != null ? param.name + "" : "";
                  },
                  position: "end",
                  backgroundColor: "#cd00cd",
                  distance: [-150, -50],
                  fontSize: 12,
                  color: "#fff",
                },
                emphasis: {
                  label: {
                    show: false,
                  },
                },
                lineStyle: {
                  color: "#646cff",
                  width: "3",
                  type: "solid",
                },
              },
              {
                // type: "max",
                coord: [findLineEndPoint?.[0], checked_detail.retrace_end],
                value: checked_detail.retrace_end,
                symbol: "arrow",
                symbolSize: 10,
              },
            ];
          }
        });
      initChart({ originData: data, pointData, lineData });
    }
  }, [data, signalData, dim]);

  const fetchData = async (params?: any) => {
    setLoading(true);
    const res = await getBiKline({
      duration: dim,
      endTime: (params && params.end) || end,
      exchange: props.exchangeName || "Binance",
      pair: (params && params.symbol) || symbol,
      startTime: (params && params.start) || start,
    });
    if (res) {
      let data = res.data;
      const newData: any = [];
      data?.forEach((item: any) => {
        newData.push([
          fnTime(Number(item.timeStamp)),
          item.open,
          item.low,
          item.high,
          item.close,
          null,
          item.timeStamp,
        ]);
      });
      setData(newData);
    }
    if (!props.signalData) {
      if (props.isBI) {
        const result: any = await getTrendHistory({
          duration: dim,
          endTime: 100000000000000000,
          exchange: "",
          pair: props.symbol,
          startTime: 0,
        });
        setSignalData(result.data);
      } else {
        const signals: any = await getSignalList({
          modeId: props.modelId,
          pageNum: 1,
          pageSize: 30,
        });
        setSignalData(signals.data.list);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [dim]);

  return (
    <View>
      <Menu
        menus={props.isBI ? kline_dim.slice(2, 3) : kline_dim.slice(0, 2)}
        selectMenu={dim}
        HandleMenu={(menu: any) => setDim(menu.key)}
      ></Menu>
      <View
        style={{
          width: "90%",
          height: 320,
          marginBottom: "5%",
          marginLeft: "5%",
          // paddingLeft: "5%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          borderRadius: 6,
        }}
      >
        {loading && (
          <View
            style={{
              width: "100%",
              height: 320,
              backgroundColor: "#eee",
              opacity: 0.7,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator />
          </View>
        )}
        {(!data || data.length < 1) && !loading && "K线追踪数据为空"}
        {<SkiaChart ref={skiaRef} />}
      </View>
    </View>
  );
}
