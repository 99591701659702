import React, { useState } from "react";
import { TouchableOpacity, StyleSheet, View } from "react-native";
import { Text, Dialog } from "react-native-paper";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import Header from "../../components/Header";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { theme } from "../../core/theme";
import { phoneValidator } from "../../helpers/emailValidator";
import { passwordValidator } from "../../helpers/passwordValidator";
import { login } from "../api";

export default function LoginScreen({ navigation }: any) {
  const [password, setPassword] = useState({ value: "", error: "" });
  const [phone, setPhone] = useState({ value: "", error: "" });
  const [visible, setVisible] = React.useState(false);
  const [tip, setTip] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const showDialog = (text: string) => {
    setTip(text);
    setVisible(true);
    setTimeout(() => {
      hideDialog();
      setTip("");
    }, 1000);
  };

  const hideDialog = () => setVisible(false);
  const onLoginPressed = async () => {
    setLoading(true);
    const phoneError = phoneValidator(phone.value);
    const passwordError = passwordValidator(password.value);
    if (phoneError || passwordError) {
      setPhone({ ...phone, error: phoneError });
      setPassword({ ...password, error: passwordError });
      setLoading(false);
      return;
    }
    const res: any = await login({
      phone: phone.value,
      password: password.value,
    });
    // {tokenHead: "Bearer ",…}
    // token
    // :
    // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNzcyNDcwODEzOSIsImNyZWF0ZWQiOjE3MDA3MTg1NDIwMzYsImV4cCI6MTgyMDcxODU0Mn0._YVcagtAqf0i2uI_QNe6LnDYvdhzxs2WV7beP2sHqSit0T7sYtpUId5ouQV813XYxj3HRk8wAlEAtTYJtq-4CA"
    // tokenHead
    // :
    // "Bearer "
    if (res && res.code === 200) {
      showDialog(res.msg);
      if (res.data && res.data.token) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data.userInfo));
      }
      navigation.reset({
        index: 0,
        routes: [{ name: "Profile" }],
      });
    } else {
      showDialog(res.msg || "登陆失败");
    }
    setLoading(false);
  };

  return (
    <Background>
      <Logo />
      <Header>Welcome back.</Header>
      <View style={{ padding: 40, width: "100%" }}>
        <TextInput
          label="Phone"
          returnKeyType="next"
          value={phone.value}
          description={""}
          onChangeText={(text: string) => setPhone({ value: text, error: "" })}
          error={!!phone.error}
          errorText={phone.error}
          autoCapitalize="none"
          autoCompleteType="phone"
          textContentType="phone"
          keyboardType="phone"
        />
        <TextInput
          label="Password"
          returnKeyType="done"
          value={password.value}
          description={""}
          onChangeText={(text: string) =>
            setPassword({ value: text, error: "" })
          }
          error={!!password.error}
          errorText={password.error}
          secureTextEntry
        />
        {/* <View style={styles.forgotPassword}>
        <TouchableOpacity
          onPress={() => navigation.navigate("ResetPasswordScreen")}
        >
          <Text style={styles.forgot}>Forgot your password?</Text>
        </TouchableOpacity>
      </View> */}
        <Button
          mode="contained"
          loading={loading}
          disabled={loading}
          onPress={onLoginPressed}
        >
          Login
        </Button>
      </View>

      <View style={styles.row}>
        <Text>Don’t have an account? </Text>
        <TouchableOpacity onPress={() => navigation.replace("RegisterScreen")}>
          <Text style={styles.link}>Sign up</Text>
        </TouchableOpacity>
      </View>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Content>
          <Text variant="bodyMedium">{tip}</Text>
        </Dialog.Content>
      </Dialog>
    </Background>
  );
}

const styles = StyleSheet.create({
  forgotPassword: {
    width: "100%",
    alignItems: "flex-end",
    marginBottom: 24,
  },
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  forgot: {
    fontSize: 13,
    color: theme.colors.secondary,
  },
  link: {
    fontWeight: "bold",
    color: theme.colors.primary,
  },
});
