import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";
import React, { useState, useEffect } from "react";
import { Text, Card, Button, Icon, ListItem } from "@rneui/themed";
import Notification from "../../components/Notification";
import Empty from "../../components/Empty";
import { Dialog } from "react-native-paper";
import {
  Process,
  ModelTypeName,
  Logo,
  Title,
  ArrowButton,
  AISkeleton,
  Background,
} from "../../components";
import BackModal from "../flist/BackModal";
import {
  getSignalList,
  getBiSignalList,
  getBiWellChosenList,
  // getBiKline,
  getLastContract,
  getSignalById,
} from "../api";
import { fnTime } from "../../helpers/day";
import { useRequest } from "ahooks";
import styles from "./style";
import Blist from "./Blist";
import { time } from "echarts";
const ONEDAY = 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

// const BTCModels = `'model_04044768104903418', 'model_7837704910203656'`;
// const ETHModels = "model_44843740513537256";

const ETHShort = "model_04044768104903418";
const ETHLong = "model_7913442533204544";
const BTCShort = "model_7837704910203656";
const BTCLong = "model_44843740513537256";

const ETHModels = `'${ETHShort}', '${ETHLong}'`;
const BTCModels = `'${BTCShort}', '${BTCLong}'`;
const allModels = [ETHShort, ETHLong, BTCShort, BTCLong];

export default function Home({ navigation }: any) {
  const pathname = location.pathname.split("/");
  const selectSignal = pathname.length > 1 ? pathname[1] : undefined;
  const [backSignarl, setBackSignarl] = useState<any>();
  const [signalList, setSignalList] = useState([]);
  const [signalListData, setSignalListData] = useState<any>({});
  const [longSignalList, setLongSignalList] = useState([]);
  const [radio, setRadio] = useState<any>({});
  const [longSignalListData, setLongSignalListData] = useState<any>({});
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);
  const [accuracyRates, setAccuracyRates] = useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [backVisible, setBackVisible] = React.useState(!!pathname);
  const [tip, setTip] = React.useState("");

  const showDialog = (text: string) => {
    setTip(text);
    setVisible(true);
    setTimeout(() => {
      hideDialog();
      setTip("");
    }, 1000);
  };

  const hideDialog = () => setVisible(false);

  // 精选币
  const {
    runAsync: runGetBiSignalList,
    data: biSignalList,
    loading: biSignalListLoading,
  } = useRequest(getBiSignalList, {
    manual: true,
  });

  // 潜力币
  const {
    runAsync: runGetBiWellChosenList,
    data: biWellChosenList,
    loading: biWellChosenListLoading,
  } = useRequest(getBiWellChosenList, {
    manual: true,
  });

  // 回测
  const { runAsync: runGetBackSignarlData, loading: backSignarlLoading } =
    useRequest(getSignalById, {
      manual: true,
    });

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      fetchData({});
      fetchAc();
    });
    return unsubscribe;
  }, []);

  const fetchData = async (params: any) => {
    setLoading(true);
    const { page, pageSize } = pagination;
    // 展示单个回测信号
    if (selectSignal) {
      const back_res: any = await runGetBackSignarlData({ id: selectSignal });
      if (back_res) {
        const data = back_res?.data?.[0];
        if (data && data.detail) {
          data.checked_detail = JSON.parse(data.detail).checked;
        }
        setBackSignarl(data);
      }
    }
    const result: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      modelId: BTCModels,
      ...params,
    });
    let radio: any = {};
    if (result && result.status === "success") {
      const { list, total } = result.data;
      let longRadio = 1;
      let shortRadio = 1;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
          if (item.model_type_name.includes("空")) {
            shortRadio += item.scoreType;
          } else {
            longRadio += item.scoreType;
          }
        }
      });
      radio.BTCUSDT = longRadio / shortRadio;
      setSignalList(list);
      setSignalListData(result.data);
      setPagination({
        pageNum: params.page || page,
        pageSize: params.pageSize || pageSize,
        total,
      });
    }
    // eth信号
    const ethResult: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      modelId: ETHModels,
      ...params,
    });
    if (ethResult && ethResult.status === "success") {
      const { list: ethList } = ethResult.data;
      let longRadio = 1;
      let shortRadio = 1;
      ethList.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
        if (item.model_type_name.includes("空")) {
          shortRadio += item.scoreType;
        } else {
          longRadio += item.scoreType;
        }
      });
      radio.ETHUSDT = longRadio / shortRadio;
      setLongSignalList(ethList);
      setLongSignalListData(ethResult.data);
    }
    setLoading(false);
    setRadio(radio);
    console.log(radio, "radio");
  };

  const SignalList = ({ signalList, symbol }: any) => {
    if (!signalList || signalList.length < 1) {
      return null;
    }
    const START = signalList[0].generateTime + ONE_HOUR;
    const END = signalList[signalList.length - 1].generateTime - ONE_HOUR;
    const TIME_SPAN = START - END > ONEDAY ? ONEDAY : START - END;
    // const topPer = (time: any) => ((START - time) / TIME_SPAN) * 100 + "%";
    const shortSignal = signalList.filter((signal: any) =>
      signal.model_type_name.includes("空")
    );
    const longSignal = signalList.filter((signal: any) =>
      signal.model_type_name.includes("多")
    );
    // 判断信号存在，否则显示无数据
    let isExist: any = {
      short: false,
      long: false,
    };
    if (shortSignal && shortSignal.length > 0) {
      isExist.short = true;
    }
    if (longSignal && longSignal.length > 0) {
      isExist.long = true;
    }

    return (
      <>
        <View>
          <Text style={styles.hLabel}>当前多空比</Text>
          <Text
            style={{
              ...styles.hValue,
              color:
                radio[`${symbol}`] >= 1
                  ? "rgb(1, 199, 129)"
                  : "rgb(229, 58, 75)",
            }}
          >
            {radio[`${symbol}`]?.toFixed(2)}
          </Text>
          <View></View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 20,
          }}
        >
          <Text>
            准确度(多):
            <Text style={{ color: "#01C781" }}>
              {symbol === "BTCUSDT"
                ? accuracyRates?.[`${BTCLong}`]
                : accuracyRates?.[`${ETHLong}`]}
              %
            </Text>
          </Text>
          <Text>
            准确度(空):
            <Text style={{ color: "#01C781" }}>
              {symbol === "BTCUSDT"
                ? accuracyRates?.[`${BTCShort}`]
                : accuracyRates?.[`${ETHShort}`]}
              %
            </Text>
          </Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          {!isExist.short && (
            <View
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                width: "50%",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Empty tips={`暂无${symbol}空信号`} />
            </View>
          )}
          {!isExist.long && (
            <View
              style={{
                position: "absolute",
                top: "30%",
                width: "50%",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Empty tips={`暂无${symbol}多信号`} />
            </View>
          )}
          <View style={styles.signalBox}>
            {signalList.map((signal: any, index: number) => (
              <ListItem
                style={{
                  // position: "absolute",
                  left: signal.model_type_name.includes("空") ? "140%" : "5%",
                  // top: topPer(signal.generateTime),
                  ...styles.td,
                }}
                key={signal.id}
                // containerStyle={index % 2 === 0 ? styles.ftdBox : {}}
              >
                <ListItem.Content style={styles.ftdBox}>
                  <TouchableOpacity
                    style={{ width: "100%" }}
                    onPress={() => {
                      if (localStorage.getItem("token")) {
                        navigation.navigate("Details", {
                          signal: signal,
                        });
                      } else {
                        showDialog("登陆查看更多详情");
                        navigation.navigate("LoginScreen");
                      }
                    }}
                  >
                    <View style={{ ...styles.tdItem, paddingBottom: 5 }}>
                      {/* {signal.model_name} &nbsp; */}

                      <View style={{ ...styles.leftLabel }}>
                        <Image
                          source={require(signal.symbol === "BTCUSDT"
                            ? "../../assets/btc.png"
                            : "../../assets/eth.png")}
                          style={{
                            width: 18,
                            height: 18,
                            marginRight: 4,
                          }}
                        />
                        {fnTime(signal.generateTime)}
                      </View>
                      <ModelTypeName model_type_name={signal.model_type_name} />
                    </View>

                    {/* <View style={styles.tdItem}>
                <Text style={styles.leftLabel}> 时间：</Text>{" "}
                {fnTime(signal.generateTime)}
              </View> */}
                    <View style={styles.tdItem}>
                      <Text style={styles.leftLabel}>周期：</Text>
                      {`${signal.scoreDetail.min_time} - ${signal.scoreDetail.max_time} 小时`}
                    </View>
                    <View style={styles.tdItem}>
                      <Text style={styles.leftLabel}>
                        {signal.model_type_name.includes("空")
                          ? "跌幅："
                          : "涨幅："}
                      </Text>
                      {signal.scoreType === 4
                        ? `大于 ${signal.scoreDetail.min_depth}`
                        : `${Number(signal.scoreDetail.min_depth).toFixed(
                            1
                          )}-${Number(signal.scoreDetail.max_depth).toFixed(
                            1
                          )}`}
                      {/* <View style={styles.tdItemLast}>
                <ArrowButton
                  size={18}
                  onPress={() => {
                    if (localStorage.getItem("token")) {
                      navigation.navigate("Details", {
                        signal: signal,
                      });
                    } else {
                      showDialog("登陆查看更多详情");
                      navigation.navigate("LoginScreen");
                    }
                  }}
                />
              </View> */}
                    </View>
                    <View style={styles.tdItem}>
                      <Text style={styles.leftLabel}>进度：</Text>
                      <Process
                        warning={signal.checked === "0"}
                        process_rate={signal.process_rate}
                      />
                    </View>
                  </TouchableOpacity>
                </ListItem.Content>
              </ListItem>
            ))}
          </View>
          <View style={styles.midBox}>
            <View style={styles.line}>
              {signalList.map((signal: any, index: number) => (
                <View
                  style={{
                    position: "absolute",
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    left: -8,
                    // opacity: 0.7,
                    backgroundColor: signal.model_type_name.includes("空")
                      ? "rgba(228, 97, 88, 0.2)"
                      : "rgba(14, 203, 129,0.2)",
                    top: `${120 * index}px`,
                  }}
                >
                  <View
                    style={{
                      width: 6,
                      height: 6,
                      margin: "auto",
                      borderRadius: 3,
                      backgroundColor: signal.model_type_name.includes("空")
                        ? "rgb(228, 97, 88)"
                        : "#01C781",
                    }}
                  ></View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </>
    );
  };

  const fetchAc = async () => {
    const acRate: any = {};
    for (let index = 0; index < allModels.length; index++) {
      const modelItem = allModels[index];
      const itemResult: any = await getSignalList({
        pageNum: 1,
        pageSize: 1,
        modelId: modelItem,
      });
      acRate[`${modelItem}`] = itemResult?.data?.accuracyRate;
    }
    setAccuracyRates(acRate);
  };

  return (
    <View style={styles.container}>
      <Logo />
      <Notification />
      <ScrollView style={{ backgroundColor: "rgb(245,247,250)" }}>
        <Title
          title="BTCUSDT"
          // rate={
          //   <Text style={styles.bottomLabel}>
          //     近{signalListData.checked_count || ""}次准确率
          //     <Text style={{ color: "#01C781" }}>
          //       {signalListData.accuracyRate || ""}%
          //     </Text>
          //   </Text>
          // }
          subTitle="查看更多"
          HandlePress={() => {
            if (localStorage.getItem("token")) {
              navigation.navigate("Flist", { select_model_id: BTCModels });
            } else {
              showDialog("登陆查看更多详情");
              navigation.navigate("LoginScreen");
            }
          }}
        ></Title>
        <SignalList signalList={signalList} symbol="BTCUSDT" />
        {loading && <AISkeleton />}
        <Title
          title="ETHUSDT"
          // rate={
          //   <Text style={styles.bottomLabel}>
          //     近{longSignalListData.checked_count || ""}次准确率
          //     <Text style={{ color: "#01C781" }}>
          //       {longSignalListData.longAccuracyRate || ""}%
          //     </Text>
          //   </Text>
          // }
          subTitle="查看更多"
          HandlePress={() => {
            if (localStorage.getItem("token")) {
              navigation.navigate("Flist", { select_model_id: ETHModels });
            } else {
              showDialog("登陆查看更多详情");
              navigation.navigate("LoginScreen");
            }
          }}
        ></Title>
        <SignalList signalList={longSignalList} symbol="ETHUSDT" />
        {/* {loading && <AISkeleton />} */}
      </ScrollView>

      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Content>
          <Text>{tip}</Text>
        </Dialog.Content>
      </Dialog>
      {selectSignal && (
        <BackModal
          visible={backVisible}
          loading={backSignarlLoading}
          hiddenDeep={true}
          setVisible={() => {
            location.pathname = "/";
            setBackVisible(false);
          }}
          signal={backSignarl}
          signalListData={signalListData}
        />
      )}
    </View>
  );
}
