import { createStackNavigator } from "@react-navigation/stack";
import Home from "../home";
import BiHome from "../biHome";
import Detail from "../detail";
import BiDetails from "../bi-detail";
import Flist from "../flist";

const Stack = createStackNavigator();

export default function App() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false, title: "首页" }}
        name="Home"
        component={Home}
      />
      <Stack.Screen
        name="Details"
        options={{ headerShown: false, title: "详情页" }}
        component={Detail}
      />
      <Stack.Screen
        name="Flist"
        options={{ headerShown: false, title: "全部信号列表" }}
        component={Flist}
      />
    </Stack.Navigator>
  );
}
