import { createStackNavigator } from "@react-navigation/stack";
import BiHome from "../biHome";
import BiDetails from "../bi-detail";

const Stack = createStackNavigator();

export default function App() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        options={{ headerShown: false, title: "潜力币" }}
        name="biHome"
        component={BiHome}
      />
      <Stack.Screen
        name="BiDetails"
        options={{ headerShown: false, title: "详情页" }}
        component={BiDetails}
      />
    </Stack.Navigator>
  );
}
