import { View, StyleSheet, TouchableOpacity, Image, Text } from "react-native";
import React, { useEffect, useState } from "react";
// import { Button as PaperButton } from "react-native-paper";
import { AiOutlineClose } from "react-icons/ai";

export default function Modal(props: any) {
  const { open, title, setOpen, children, ...nextProps } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(!!open);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return modalOpen ? (
    <View style={styles.container}>
      <View style={styles.box}>
        <TouchableOpacity
          onPress={() => {
            // setModalOpen(false);
            setOpen(false);
          }}
          style={styles.cancel}
        >
          <AiOutlineClose size={18} fill="#C8C9CC"></AiOutlineClose>
        </TouchableOpacity>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View>{children}</View>
      </View>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    zIndex: 9999,
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.50)",
    fontFamily: "PingFangSC-Medium",
  },
  box: {
    position: "absolute",
    width: "100%",
    height: 450,
    bottom: 0,
    left: 0,
    backgroundColor: "#fff",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  title: {
    width: "100%",
    height: 48,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    zIndex: 1,
    color: "#323233",
    fontWeight: "bold",
    fontFamily: "PingFangSC-Medium",
  },
  cancel: {
    position: "absolute",
    zIndex: 2,
    right: 20,
    top: 16,
  },
});
