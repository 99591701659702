import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  hBox: {},
  hValue: {
    fontSize: 24,
    color: "rgb(229, 58, 75)",
    fontWeight: "bold",
    textAlign: "center",
  },
  hLabel: {
    color: "#616F85",
    textAlign: "center",
    fontSize: 12,
  },
  td: {
    // flexDirection: "row",
    // justifyContent: "space-between",
    width: "100%",
    backgroundColor: "rgb(245,247,250)",
    marginBottom: 16,
    // marginLeft: 16,
    borderRadius: 16,
  },
  signalBox: {
    width: "40%",
    height: "60vh",
    marginBottom: 80,
  },
  midBox: {
    width: "20%",
    textAlign: "center",
    height: "100%",
    justifyContent: "center",
  },
  line: {
    width: 1,
    height: "90%",
    backgroundColor: "#ccc",
    margin: "auto",
  },
  ftdBox: {
    width: 150,
    textAlign: "left",
    flexWrap: "nowrap",
    borderRadius: 8,
    // backgroundColor: "#F7FCFF",
  },
  tdItem: {
    width: "100%",
    fontSize: 10,
    lineHeight: 19,
    // color: "#616F85",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tdItemLast: {
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
  },
  leftLabel: {
    fontSize: 12,
    color: "#323233",
    flexDirection: "row",
  },
  bottomLabel: {
    fontSize: 10,
    lineHeight: 19,
    color: "#616F85",
  },
});

export default styles;
