import http from "../api/singarl-api";
import signa_http from "../api/api";

export const getSignalList = async (params: any) => {
  return http({
    url: "/model/signal/list",
    method: "get",
    params,
  });
};

export const getSignalById = async (params: any) => {
  return http({
    url: "/model/signal/single",
    method: "get",
    params,
  });
};

export const getPrice = (params: any) => {
  return http({
    url: "/defi/price",
    method: "get",
    params,
  });
};

export const getBiSignalList = async (data: any) => {
  return http({
    url: "/bi/signal_list",
    method: "post",
    data,
  });
};

export const getBiWellChosenList = async (data: any) => {
  return http({
    url: "/bi/signal_list2",
    method: "post",
    data,
  });
};

export const getBiEvent = async (data: any) => {
  return http({
    url: "/bi/event",
    method: "post",
    data,
  });
};

export const getBiWiki = async (data: any) => {
  return http({
    url: "/bi/wiki",
    method: "post",
    data,
  });
};

export const getNewFlash = async (data: any) => {
  return http({
    url: "/bi/new_flash",
    method: "post",
    data,
  });
};

export const getNewFlashDetail = async (data: any) => {
  return http({
    url: "/bi/new_flash_detail",
    method: "post",
    data,
  });
};

export const getBiKline = async (data: any) => {
  return http({
    url: "/bi/kline",
    method: "post",
    data,
  });
};

export const getLastContract = async (data: any) => {
  return http({
    url: "/bi/last_contract",
    method: "post",
    data,
  });
};

export const getTrendHistory = async (data: any) => {
  return http({
    url: "/bi/trend_history",
    method: "post",
    data,
  });
};

// |参数|是否必选|类型|说明|
// |:-----|:-------:|:-----|:-----|
// |phone          |Y       |string   | |
// |password       |Y       |string   | |

/**
 * login
 * @param data
 * @returns
 */

export const login = async (data: any) => {
  return http({
    url: "/aisigna/login",
    method: "post",
    data,
  });
};

// |参数|是否必选|类型|说明|
// |:-----|:-------:|:-----|:-----|
// |phone               |Y       |string   | |
// |password            |Y       |string   | |
// |confimpassword      |Y       |string   | |
// |invitecode          |N       |string   | 邀请没|
export const register = async (data: any) => {
  return http({
    url: "/aisigna/register",
    method: "post",
    data,
  });
};
