import { View, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import { Text, Card, Button, Icon, ListItem } from "@rneui/themed";
import Notification from "../../components/Notification";
import { Dialog } from "react-native-paper";
import {
  Process,
  ModelTypeName,
  Logo,
  Title,
  ArrowButton,
  AISkeleton,
} from "../../components";
import BackModal from "../flist/BackModal";
import {
  getSignalList,
  getBiSignalList,
  getBiWellChosenList,
  // getBiKline,
  getLastContract,
  getSignalById,
} from "../api";
import { fnTime } from "../../helpers/day";
import { useRequest } from "ahooks";
import styles from "./style";
import Blist from "./Blist";
const ONEDAY = 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

export default function biHome({ navigation }: any) {
  const pathname = location.pathname.split("/");
  const selectSignal = pathname.length > 1 ? pathname[1] : undefined;
  const [signalList, setSignalList] = useState([]);
  const [backSignarl, setBackSignarl] = useState<any>();
  const [signalListData, setSignalListData] = useState<any>({});
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const [backVisible, setBackVisible] = React.useState(!!pathname);
  const [tip, setTip] = React.useState("");

  const showDialog = (text: string) => {
    setTip(text);
    setVisible(true);
    setTimeout(() => {
      hideDialog();
      setTip("");
    }, 1000);
  };

  const hideDialog = () => setVisible(false);

  // 精选币
  const {
    runAsync: runGetBiSignalList,
    data: biSignalList,
    loading: biSignalListLoading,
  } = useRequest(getBiSignalList, {
    manual: true,
  });

  // 潜力币
  const {
    runAsync: runGetBiWellChosenList,
    data: biWellChosenList,
    loading: biWellChosenListLoading,
  } = useRequest(getBiWellChosenList, {
    manual: true,
  });

  // 回测
  const { runAsync: runGetBackSignarlData, loading: backSignarlLoading } =
    useRequest(getSignalById, {
      manual: true,
    });

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      fetchData({});
      runGetBiWellChosenList({
        current: 1,
        size: 5,
      });
      runGetBiSignalList({
        current: 1,
        size: 5,
      });
    });
    return unsubscribe;
  }, []);

  const fetchData = async (params: any) => {
    setLoading(true);
    const { page, pageSize } = pagination;

    if (selectSignal) {
      const back_res: any = await runGetBackSignarlData({ id: selectSignal });
      if (back_res) {
        const data = back_res?.data?.[0];
        if (data && data.detail) {
          data.checked_detail = JSON.parse(data.detail).checked;
        }
        setBackSignarl(data);
      }
    }
    const result: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      ...params,
    });
    if (result && result.status === "success") {
      const { list, total } = result.data;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
      });
      setSignalList(list);
      setSignalListData(result.data);
      setPagination({
        pageNum: params.page || page,
        pageSize: params.pageSize || pageSize,
        total,
      });
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Logo />
      <Notification />
      <ScrollView>
       
        <Blist
          title={"最新5日潜力单币信号"}
          list={biWellChosenList?.data?.records}
          navigation={navigation}
          loading={biWellChosenListLoading}
          showDialog={showDialog}
        />
        <Blist
          title={"精选高收益单币信号30日"}
          list={biSignalList?.data}
          navigation={navigation}
          showDialog={showDialog}
          loading={biSignalListLoading}
        />
      </ScrollView>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Content>
          <Text>{tip}</Text>
        </Dialog.Content>
      </Dialog>
      {selectSignal && (
        <BackModal
          visible={backVisible}
          loading={backSignarlLoading}
          hiddenDeep={true}
          setVisible={() => {
            location.pathname = "/";
            setBackVisible(false);
          }}
          signal={backSignarl}
          signalListData={signalListData}
        />
      )}
    </View>
  );
}
