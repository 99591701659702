import styles from "./style";
import React from "react";
import { Text, ListItem } from "@rneui/themed";
import { View } from "react-native";

import {
  ModelTypeName,
  ArrowButton,
  Title,
  AISkeleton,
} from "../../components";

export default function Blist({ title, list, navigation, loading,showDialog }: any) {
  return (
    <View>
      <Title
        title={title}
        HandlePress={() => {
          if (localStorage.getItem("token")) {
            navigation.navigate("Flist");
          } else {
            showDialog('登陆查看更多详情')
            navigation.navigate("LoginScreen");
          }
        }}
      ></Title>
      {loading && <AISkeleton />}
      {list?.map((signal: any, index: number) => (
        <ListItem
          key={signal.id}
          containerStyle={index % 2 === 0 ? styles.ftdBox : {}}
        >
          <ListItem.Content>
            <View style={styles.td}>
              <View style={{ width: "40%" }}>
                <Text style={styles.topLabel}>
                  {signal.contract} &nbsp;
                  <ModelTypeName model_type_name={signal.signalSuggestion} />
                </Text>
                <Text style={styles.bottomLabel}>{signal.time}</Text>
              </View>
              <View style={styles.tdItem}>
                <Text style={styles.topLabel}>{signal.exitPrice}</Text>
                <Text style={styles.bottomLabel}>开仓价/最新价 </Text>
              </View>
              <View style={styles.tdItem}>
                <Text
                  style={{
                    color:
                      Number(signal.profitLossRatio) < 0
                        ? "#E53A4B"
                        : "#01C781",
                    fontSize: 12,
                  }}
                >
                  {signal.profitLossRatio + "%"}
                </Text>
                <Text style={styles.bottomLabel}>累计涨幅</Text>
              </View>
              <View style={styles.tdItemLast}>
                <ArrowButton
                  size={18}
                  onPress={() => {
                    if (localStorage.getItem("token")) {
                      navigation.navigate("BiDetails", {
                        signal: signal,
                      });
                    } else {
                      showDialog('登陆查看更多详情')
                      navigation.navigate("LoginScreen");
                    }
                  }}
                />
              </View>
            </View>
          </ListItem.Content>
        </ListItem>
      ))}
    </View>
  );
}
