import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Text } from "@rneui/themed";

interface Props {
  title: string;
  subTitle?: string;
  HandlePress?: any;
  rate?: any;
}

export default function Title({ title, subTitle, HandlePress, rate }: Props) {
  const signarlList = [
    { label: "邮箱", key: "mail" },
    { label: "手机", key: "phone" },
    { label: "邀请码", key: "inviteCode" },
    { label: "到期时间", key: "time" },
  ];
  return (
    <View style={styles.titleBox}>
      <Text style={styles.title}>
        {title} {rate}
      </Text>
      <TouchableOpacity onPress={() => HandlePress && HandlePress()}>
        <Text style={{ fontSize: 10, color: "#9A9A9A", lineHeight: 19 }}>
          {subTitle}
        </Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  titleBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 15,
  },
  title: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#0A054F",
    letterSpacing: 0,
  },
});
