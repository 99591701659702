import dayjs from "dayjs";

export const fnDate = (date: any, unit?: "s" | "d") => {
  if (!date) {
    return "-";
  }
  const rule = unit && unit === "s" ? "YYYY/MM/DD HH:mm:ss" : "YYYY/MM/DD";
  return dayjs(date).format(rule);
};

export const fnDateLine = (date: any, unit?: "s" | "d") => {
  if (!date) {
    return "-";
  }
  const rule = unit && unit === "s" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
  return dayjs(date).format(rule);
};

export const getAddDayDateLine = (
  date: any,
  unit?: "s" | "d",
  days?: number
) => {
  if (!date) {
    return "-";
  }
  const rule = unit && unit === "s" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD";
  return dayjs(date)
    .add(days || 0, "day")
    .format(rule);
};

export const fnTime = (date: any) => {
  if (!date) {
    return "-";
  }
  const rule = "MM/DD HH:mm";
  return dayjs(date).format(rule);
};


export const fnMTime = (date: any) => {
  if (!date) {
    return "-";
  }
  const rule = "HH:mm";
  return dayjs(date).format(rule);
};


// 封装的函数
export function formatTimestamp(timestamp: number|string, format: 'm' | 'h' | 'd' = 'h'): string {
  const date = new Date(Number(timestamp));
  // var year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  let formattedDate = `${month}/${day}`;

  if (format === 'h') {
      formattedDate += ` ${hours}`;
  } else if (format === 'm') {
      formattedDate += ` ${hours}:${minutes}`;
  }

  return formattedDate;
}
