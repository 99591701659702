import { View, StyleSheet } from "react-native";
import { Text } from "@rneui/themed";

import { ModelTypeName, Modal, CandleStick } from "../../components";
import React from "react";
import { fnTime } from "../../helpers/day";

const ONEDAY = 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

export default function BackModal({
  visible,
  setVisible,
  signal,
  signalListData,
  hiddenDeep,
  loading,
}: any) {
  const isLong = signal?.model_type_name.includes("多");
  if (isLong && signal?.checked_detail?.long) {
    signal.checked_detail = {
      ...signal.checked_detail,
      ...signal.checked_detail.long
    }
  }
  return (
    <Modal
      open={visible}
      setOpen={(open: boolean) => setVisible && setVisible(open)}
      title="Signal backtest results"
    >
      {signal && (
        <View
          style={{
            ...styles.td,
            marginLeft: "4%",
            // marginRight: "5%",
            width: "90%",
          }}
        >
          <View style={{ width: "40%" }}>
            <Text style={styles.topLabel}>
              {signal.model_name} &nbsp;
              <ModelTypeName model_type_name={signal.model_type_name} />
            </Text>
            <Text style={styles.bottomLabel}>
              近{signalListData.checked_count || ""}次准确率
              <Text style={{ color: "#01C781" }}>
                {signalListData.accuracyRate || ""}
                {"%"}
              </Text>
            </Text>
          </View>
          {!hiddenDeep && (
            <View style={styles.tdItem}>
              <Text style={styles.topLabel}>
                {signal.scoreType === 4
                  ? `大于 ${signal.scoreDetail.min_depth}`
                  : `${signal.scoreDetail.min_depth} - ${signal.scoreDetail.max_depth}`}
              </Text>
              <Text style={styles.bottomLabel}>预计跌幅（USDT） </Text>
            </View>
          )}
          <View style={styles.tdItem}>
            <Text style={styles.topLabel}>
              {fnTime(signal?.generateTime)}-
              {fnTime(
                signal?.generateTime + signal.scoreDetail.max_time * ONE_HOUR
              )}
            </Text>

            <Text style={styles.bottomLabel}>触发/结束时间</Text>
          </View>
        </View>
      )}
      {signal && signal.checked ? (
        <CandleStick
          start={
            signal.checked_detail
              ? Number(signal.generateTime) - ONEDAY
              : undefined
          }
          end={
            signal.checked_detail
              ? Number(signal.generateTime) +
                signal.scoreDetail.max_time * ONE_HOUR +
                ONEDAY / 2
              : undefined
          }
          symbol={signal.text.indexOf("ETH") > -1 ? "ETHUSDT" : "BTCUSDT"}
          signalData={[signal]}
        ></CandleStick>
      ) : (
        <Text
          style={{
            margin: "auto",
            marginTop: loading ? 90 : 60,
            textAlign: "center",
          }}
        >
          {loading
            ? "loading..."
            : signal
            ? "The signal is being backtested..."
            : "The signal does not exist"}
        </Text>
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  td: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 35,
  },
  tdItem: {
    alignItems: "center",
    justifyContent: "center",
  },
  topLabel: {
    fontSize: 12,
    lineHeight: 19,
    color: "#323233",
  },
  bottomLabel: {
    fontSize: 10,
    lineHeight: 19,
    color: "#616F85",
  },
});
