import React from "react";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Paragraph from "../../components/Paragraph";
import { View } from "react-native";

export default function StartScreen({ navigation }: any) {
  if (localStorage.getItem("token")) {
    navigation.reset({
      index: 0,
      routes: [{ name: "Profile" }],
    });
  }
  return (
    <Background>
      <Logo />
      <Header>Start Login</Header>
      <Paragraph>
        The easiest way to start with your amazing application.
      </Paragraph>
      <View style={{ padding: 40, width: "100%" }}>
        <Button
          mode="contained"
          onPress={() => navigation.navigate("LoginScreen")}
        >
          Login
        </Button>
        <Button
          mode="outlined"
          onPress={() => navigation.navigate("RegisterScreen")}
        >
          Sign Up
        </Button>
      </View>
    </Background>
  );
}
