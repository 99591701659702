import React from "react";
import { Text, StyleSheet } from "react-native";

export default function ModelTypeName({
  model_type_name,
}: {
  model_type_name: string;
}) {
  const styles = StyleSheet.create({
    model_container: {
      fontSize: 10,
      backgroundColor:
        model_type_name.indexOf("多") > -1 ? "#DDFBEE" : "#FFE4E7",
      borderRadius: 2,
      color: model_type_name.indexOf("多") > -1 ? "#01C781" : "#E53A4B",
      padding: 3,
      alignItems: "center",
      justifyContent: "center",
    },
  });
  

  return <Text style={styles.model_container}>{model_type_name}</Text>;
}
