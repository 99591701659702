import { View, ScrollView, StyleSheet, Image } from "react-native";
import { Text, Card, Avatar, Icon } from "@rneui/themed";
import Button from "../../components/Button";

export default function Profile({ navigation }: any) {
  let userInfo: any = localStorage.getItem("userInfo");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }
  const profileList = [
    { label: "手机号", key: "phone" },
    { label: "我的邀请码", key: "id" },
    { label: "到期时间", key: "-" },
  ];
  const shareList = [
    // { label: "邀请人数", value: "2" },
    { label: "积分", value: "integration" },
  ];
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.user}>
          <Text
            style={{
              width: 84,
              margin: "auto",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <Avatar size={84} rounded source={{ uri: userInfo?.avatar }} />
          </Text>
          <Text style={styles.name}>
            {userInfo?.nickname || userInfo?.username}
          </Text>
          {/* <Text style={styles.identity}>vip</Text> */}
        </View>
        <Card
          containerStyle={{
            backgroundColor: "white",
            borderRadius: 6,
            borderWidth: 0,
          }}
        >
          {profileList.map((pro: any) => (
            <View style={styles.proItem}>
              <Text style={styles.label}>{pro.label}:</Text>
              <Text style={styles.value}>{userInfo?.[pro?.key]}</Text>
            </View>
          ))}
        </Card>
        <Card
          containerStyle={{
            backgroundColor: "white",
            borderRadius: 6,
            borderWidth: 0,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {shareList.map((item: any) => (
              <View style={styles.midItem}>
                <Text style={styles.label}>{item.label}:</Text>
                <Text style={styles.midValue}>{userInfo?.[item.value]}</Text>
              </View>
            ))}
          </View>
        </Card>
        {/* <Button mode="contain" onPress={() => {}}>
          Login
        </Button> */}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  user: {
    marginTop: 20,
    marginBottom: 15,
    textAlign: "center",
  },
  image: {
    width: 84,
    height: 84,
    marginBottom: 15,
    borderRadius: 42,
  },
  name: {
    fontSize: 18,
    fontWeight: "600",
    color: "#0A054F",
    marginBottom: 7,
  },
  identity: {
    fontSize: 12,
    color: "#86878F",
    lineHeight: 18,
  },
  proItem: {
    flexDirection: "row",
    fontSize: 12,
    lineHeight: 20,
    padding: 12,
  },
  label: {
    color: "#86878F",
    marginRight: 10,
  },
  value: {
    color: "#323233",
  },
  midItem: {
    width: 120,
    fontSize: 12,
    lineHeight: 20,
    padding: 12,
  },
  midValue: {
    fontSize: 20,
    color: "#323233",
    lineHeight: 20,
    marginTop: 8,
  },
});
