import React from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
} from "react-native";

export default function Empty({ tips }: any) {
  return (
    <View>
      <Image style={styles.image} source={require("../assets/empty.svg")} />
      <Text style={styles.text}>{tips || "暂无信号"}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: 150,
    height: 150,
  },
  text: {
    textAlign: "center",
    color: "#999",
  },
});
