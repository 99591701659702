import {
  View,
  SafeAreaView,
  FlatList,
  StyleSheet,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { Text, ListItem } from "@rneui/themed";
import BackModal from "./BackModal";
import {
  Process,
  ModelTypeName,
  Logo,
  ArrowButton,
  Modal,
  TopHeader,
  CandleStick,
} from "../../components";
import React, { useState, useEffect } from "react";
import { getSignalList, getTrendHistory } from "../api";
import { fnTime } from "../../helpers/day";
const longModels = "model_44843740513537256";

// onPress={() => navigation.navigate("Details")}
interface Column {
  title: string;
  dataIndex: string;
  key: string;
  width?: any;
  render?: any;
  justifyContent?: any;
  alignItems?: any;
}
const ONEDAY = 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

export default function Flist({ navigation, route }: any) {
  const { params } = route;
  const route_signal = params?.signal;
  const select_model_id = params?.select_model_id;
  const isLong = route_signal
    ? params?.signal?.model_type_name.includes("多")
    : false;
  // const isBI = !!from;
  // console.log(isBI, "isBI");

  const [signalList, setSignalList] = useState([]);
  const [signal, setSignal] = useState<any>(); // select signal
  const [signalListData, setSignalListData] = useState<any>({});
  const [visible, setVisible] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 40,
  });
  const [loading, setLoading] = useState(false);
  const wait = (timeout: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);
  useEffect(() => {
    fetchData({});
  }, []);

  const fetchData = async (putData: any) => {
    // console.log('====================================');
    // console.log(route_signal);
    // console.log('====================================');
    setLoading(true);
    // if (isBI) {
    //   const result: any = await getTrendHistory({
    //     duration: "4H",
    //     endTime: 100000000000000000,
    //     exchange: "",
    //     pair: route_signal.symbol,
    //     startTime: 0,
    //   });
    //   if (result && result.status === "success") {
    //     const { list, total } = result.data;
    //     list.forEach((item: any) => {
    //       if (item.detail) {
    //         item.checked_detail = JSON.parse(item.detail).checked;
    //       }
    //     });
    //     setSignalList(list);
    //   }
    // } else {
    const { page, pageSize } = pagination;
    const result: any = await getSignalList({
      pageNum: page,
      pageSize: pageSize,
      modelId: route_signal?.modelId || select_model_id || "",
      ...putData,
    });
    if (result && result.status === "success") {
      const { list, total } = result.data;
      list.forEach((item: any) => {
        if (item.detail) {
          item.checked_detail = JSON.parse(item.detail).checked;
        }
      });
      if (putData.pageNum > 1) {
        //@ts-ignore
        const newList: any = signalList.concat(list);
        setSignalList(newList);
      } else {
        setSignalListData(result.data);
        setSignalList(list);
      }
      setPagination({
        page: putData.pageNum || page,
        pageSize: putData.pageSize || pageSize,
        total,
      });
    }
    // }

    setLoading(false);
  };

  const columns: Column[] = [
    {
      title: "币种",
      dataIndex: "symbol",
      key: "symbol",
      width: "20%",
    },
    {
      title: "触发时间",
      dataIndex: "generateTime",
      key: "generateTime",
      width: "20%",
      render: (record: any) => {
        return (
          <Text style={styles.bottomLabel}>{fnTime(record?.generateTime)}</Text>
        );
      },
    },
    {
      title: "预计跌幅",
      dataIndex: "deepth",
      key: "deepth",
      width: "20%",
      render: (record: any) => {
        return (
          <Text style={styles.topLabel}>
            {record.scoreType === 4
              ? `大于 ${record.scoreDetail.min_depth}`
              : `${Number(record.scoreDetail.min_depth).toFixed(1)}-${Number(
                  record.scoreDetail.max_depth
                ).toFixed(1)}`}
          </Text>
        );
      },
    },
    {
      title: "信号强度",
      dataIndex: "type",
      key: "type",
      width: "13%",
      render: (record: any) => {
        return <ModelTypeName model_type_name={record.model_type_name} />;
      },
    },
    {
      title: "剩余结束时间",
      dataIndex: "process_rate",
      key: "process_rate",
      width: "20%",
      render: (record: any) => {
        return (
          <Process
            warning={isLong ? record.longChecked === 0 : record.checked === "0"}
            process_rate={record.process_rate}
          />
        );
      },
    },
    {
      title: "回测",
      dataIndex: "operate",
      key: "operate",
      width: "10%",
      render: (record: any) => {
        return record.checked ? (
          <ArrowButton
            onPress={() => {
              setVisible(true);
              setSignal(record);
            }}
          />
        ) : null;
      },
    },
  ];

  const renderItem = ({ signalItem, index }: any) => {
    return (
      signalItem && (
        <ListItem
          key={signalItem.generateTime}
          containerStyle={{
            height: 35,
            backgroundColor: index % 2 === 1 ? "#FAF9FE" : "#FFFFFF",
          }}
        >
          <ListItem.Content>
            <View style={styles.td}>
              {columns.map((column: any) => (
                <View
                  style={{
                    width: column.width,
                    display: "flex",

                    alignItems: column.alignItems || "center",
                    justifyContent: column.justifyContent || "center",
                  }}
                >
                  {column.render ? (
                    column.render(signalItem)
                  ) : (
                    <Text style={styles.topLabel}>
                      {signalItem?.[column.key]}
                    </Text>
                  )}
                </View>
              ))}
            </View>
          </ListItem.Content>
        </ListItem>
      )
    );
  };

  return (
    <SafeAreaView style={{ width: "100%", height: "100%" }}>
      <View style={styles.container}>
        <TopHeader
          navigation={navigation}
          title={
            route_signal?.model_name ||
            `由AI驱动的金牌${isLong ? "多" : "空"}信号`
          }
        />
        <Logo />
        <ListItem
          containerStyle={{
            height: 35,
            backgroundColor: "#FAF9FE",
          }}
        >
          <View style={{ width: "100%", flexDirection: "row" }}>
            {columns.map((column: Column, index) => (
              <Text
                key={column.key}
                style={{
                  fontSize: 10,
                  lineHeight: 19,
                  color: "#616F85",
                  display: "flex",
                  width: column.width || "10%",
                  justifyContent: column.justifyContent || "center",
                  alignItems: column.alignItems || "center",
                }}
              >
                {column.title}
              </Text>
            ))}
          </View>
          <ListItem.Content></ListItem.Content>
        </ListItem>
        <FlatList
          data={signalList || []}
          renderItem={({ item, index }) =>
            renderItem({ signalItem: item, index })
          }
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          onEndReached={() => {
            if (pagination.total > pagination.page * pagination.pageSize) {
              let pageNum = pagination.page + 1;
              fetchData({
                pageNum,
              });
            }
          }}
          onEndReachedThreshold={0.5}
          ListFooterComponent={
            pagination.total <= pagination.page * pagination.pageSize ? (
              <Text style={{ textAlign: "center", margin: 20 }}>
                数据已加载完毕
              </Text>
            ) : loading ? (
              <ActivityIndicator style={{ margin: 20 }} />
            ) : null
          }
          ListEmptyComponent={
            <View>
              {loading ? null : (
                <Text style={{ textAlign: "center", margin: 20 }}>
                  数据为空
                </Text>
              )}
            </View>
          }
          keyExtractor={(item: any) => item.id}
        />
      </View>
      <BackModal
        visible={visible}
        setVisible={setVisible}
        signal={signal}
        signalListData={signalListData}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "rgb(245,247,250)",
  },
  td: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 35,
  },
  tdItem: {
    alignItems: "center",
    justifyContent: "center",
  },
  topLabel: {
    fontSize: 12,
    lineHeight: 19,
    color: "#323233",
  },
  bottomLabel: {
    fontSize: 10,
    lineHeight: 19,
    color: "#616F85",
  },
});
