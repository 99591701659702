import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Text, Dialog} from "react-native-paper";
import Background from "../../components/Background";
import Logo from "../../components/Logo";
import Header from "../../components/Header";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { theme } from "../../core/theme";
import {
  phoneValidator,
  inviteCodeValidator,
} from "../../helpers/emailValidator";
import { passwordValidator } from "../../helpers/passwordValidator";
// import { confirmPasswordValidator } from "../../helpers/confirmPasswordValidator";
import { register } from "../api";

export default function RegisterScreen({ navigation }: any) {
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: "",
  });
  const [phone, setPhone] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [inviteCode, setInviteCode] = useState({ value: "", error: "" });
  // const [phone, setPhone] = useState({ value: "", error: "" });
  const [visible, setVisible] = React.useState(false);
  const [tip, setTip] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const showDialog = (text: string) => {
    setTip(text);
    setVisible(true);
    setTimeout(() => {
      hideDialog();
      setTip("");
    }, 1000);
  };

  const hideDialog = () => setVisible(false);
  const onSignUpPressed = async () => {
    setLoading(true);

    let confirmPasswordError = passwordValidator(confirmPassword.value);
    const phoneError = phoneValidator(phone.value);
    const passwordError = passwordValidator(password.value);
    const inviteCodeError = inviteCodeValidator(inviteCode.value);

    if (confirmPassword.value !== password.value) {
      confirmPasswordError = "两次输入的密码不一致";
    }
    if (
      phoneError ||
      passwordError ||
      confirmPasswordError ||
      inviteCodeError
    ) {
      setConfirmPassword({ ...confirmPassword, error: confirmPasswordError });
      setPhone({ ...phone, error: phoneError });
      setPassword({ ...password, error: passwordError });
      setLoading(false);
      return;
    }
    // |phone               |Y       |string   | |
    // |password            |Y       |string   | |
    // |confimpassword      |Y       |string   | |
    // |invitecode          |N       |string   | 邀请没|
    const res: any = await register({
      phone: phone.value,
      password: password.value,
      confimpassword: confirmPassword.value,
      invitecode: inviteCode.value,
    });

    if (res && res.code === 200) {
      showDialog(res.msg);
      setTimeout(() => {
        navigation.navigate("LoginScreen");
      }, 1000);
    } else {
      showDialog(res.msg || "注册失败");
    }
    setLoading(false);
  };

  return (
    <Background>
      <Logo />
      <Header>Create Account</Header>
      <View style={{ padding: 40, width: "100%" }}>
        <TextInput
          label="Phone"
          returnKeyType="next"
          value={phone.value}
          description={""}
          onChangeText={(text: string) => setPhone({ value: text, error: "" })}
          error={!!phone.error}
          errorText={phone.error}
          autoCapitalize="none"
          autoCompleteType="phone"
          textContentType="phone"
          keyboardType="phone"
        />
        <TextInput
          label="InviteCode"
          returnKeyType="next"
          value={inviteCode.value}
          description={""}
          onChangeText={(text: string) =>
            setInviteCode({ value: text, error: "" })
          }
          error={!!inviteCode.error}
          errorText={inviteCode.error}
          // secureTextEntry
        />
        <TextInput
          label="Password"
          returnKeyType="next"
          value={password.value}
          description={""}
          onChangeText={(text: string) =>
            setPassword({ value: text, error: "" })
          }
          error={!!password.error}
          errorText={password.error}
          secureTextEntry
        />
        <TextInput
          label="ConfirmPassword"
          returnKeyType="done"
          value={confirmPassword.value}
          description={""}
          onChangeText={(text: string) =>
            setConfirmPassword({ value: text, error: "" })
          }
          error={!!confirmPassword.error}
          errorText={confirmPassword.error}
          secureTextEntry
        />
        <Button
          loading={loading}
          disabled={loading}
          mode="contained"
          onPress={onSignUpPressed}
          style={{ marginTop: 24 }}
        >
          Sign Up
        </Button>
      </View>
      <View style={styles.row}>
        <Text>Already have an account? </Text>
        <TouchableOpacity onPress={() => navigation.replace("LoginScreen")}>
          <Text style={styles.link}>Login</Text>
        </TouchableOpacity>
      </View>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Content>
          <Text variant="bodyMedium">{tip}</Text>
        </Dialog.Content>
      </Dialog>
    </Background>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  link: {
    fontWeight: "bold",
    color: theme.colors.primary,
  },
});
