import { createStackNavigator } from "@react-navigation/stack";
import Profile from "../profile";
import {
  StartScreen,
  LoginScreen,
  RegisterScreen,
  ResetPasswordScreen,
} from "../login";

const Stack = createStackNavigator();

export default function App() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="StartScreen"
        options={{ headerShown: false, title: "登陆" }}
        component={StartScreen}
      />
      <Stack.Screen
        options={{ headerShown: false, title: "个人信息" }}
        name="Profile"
        component={Profile}
      />
      <Stack.Screen
        name="LoginScreen"
        options={{ headerShown: false, title: "登陆" }}
        component={LoginScreen}
      />
      <Stack.Screen
        name="RegisterScreen"
        options={{ headerShown: false, title: "注册" }}
        component={RegisterScreen}
      />
      <Stack.Screen
        name="ResetPasswordScreen"
        options={{ headerShown: false, title: "重置密码" }}
        component={ResetPasswordScreen}
      />
    </Stack.Navigator>
  );
}
