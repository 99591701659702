import React from "react";
import { View } from "react-native";
import { Skeleton } from "@rneui/themed";

export default function AISkeleton({ goBack }: any) {
  return (
    <View>
      {[1, 2, 3, 4, 5].map((item) => (
        <View
          style={{
            margin: 16,
          }}
          key={item}
        >
          <View
            style={{
              flexDirection: "row",
              margin: 2,
              justifyContent: "space-between",
            }}
          >
            <Skeleton width={100} height={15} />
            <Skeleton width={100} height={15} />
            <Skeleton width={100} height={15} />
          </View>
          <View
            style={{
              flexDirection: "row",
              margin: 2,
              justifyContent: "space-between",
            }}
          >
            <Skeleton width={100} height={15} />
            <Skeleton width={100} height={15} />
            <Skeleton width={100} height={15} />
          </View>
        </View>
      ))}
    </View>
  );
}
