import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
} from "react-native";

type menuItem = {
  label: string;
  key: number | string;
};
interface Props {
  menus: menuItem[];
  selectMenu: number | string;
  HandleMenu: any;
}

export default function Menu({ menus, selectMenu, HandleMenu }: Props) {
  return (
      <View style={styles.container}>
        {menus.map((menu: menuItem) => (
          <TouchableOpacity
            key={menu.key}
            style={{ marginLeft: 17, marginRight: 10 }}
            onPress={() => HandleMenu && HandleMenu(menu)}
          >
            <Text
              style={{
                lineHeight: 40,
                borderBottomWidth: selectMenu === menu.key ? 3 : 0,
                borderColor: "#3F4DDC",
              }}
            >
              {menu.label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    height: 40,
    alignItems: "center",
    marginBottom:2
  },
});
