export function emailValidator(email: string) {
  const re = /\S+@\S+\.\S+/;
  if (!email) return "Email can't be empty.";
  if (!re.test(email)) return "Ooops! We need a valid email address.";
  return "";
}

export function phoneValidator(phone: string) {
  const re = /^1[0-9]{10}$/;
  if (!phone) return "Phone can't be empty.";
  if (!re.test(phone)) return "Ooops! We need a valid Phone.";
  return "";
}



export function inviteCodeValidator(inviteCode: string) {
  if (!inviteCode) return "inviteCode can't be empty.";
  if (inviteCode.length < 3)
    return "inviteCode must be at least 3 characters long.";
  return "";
}