import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Text, ListItem } from "@rneui/themed";
import React from "react";

interface Column {
  title: string;
  dataIndex: string;
  key: string;
  width?: any;
  render?: any;
  color?: any;
  justifyContent?: any;
  alignItems?: any;
}

export default function BiSigalItem({ navigation, signal }: any) {
  const columns: Column[] = [
    {
      title: "信号收益",
      dataIndex: "profitLossRatio",
      key: "profitLossRatio",
      color: (record: any) => {
        return Number(record.profitLossRatio) < 0 ? "#E53A4B" : "#01C781";
      },
      render: (record: any) => {
        return `${record.profitLossRatio}%`;
      },
    },
    {
      title: "级别",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "价格",
      dataIndex: "exitPrice",
      key: "exitPrice",
      render: (record: any) => {
        return record?.exitPrice ? `$${record.exitPrice}` : "-";
      },
    },
    {
      title: "多空方向",
      dataIndex: "many",
      key: "many",
    },
    {
      title: "信号",
      dataIndex: "signer",
      key: "signer",
      color: (record: any) => {
        return !!record?.isUp ? "#1AC886" : "#323233";
      },
    },
    {
      title: "多空比",
      dataIndex: "longShortRatio",
      key: "longShortRatio",
    },
    {
      title: "信号建议",
      dataIndex: "signalSuggestion",
      key: "signalSuggestion",
    },
    {
      title: "关联信号",
      dataIndex: "lastSignalSuggestion",
      key: "lastSignalSuggestion",
      render: (record: any) => {
        return `${record.lastSignalSuggestion}${
          record.lastPrice ? " $" + record.lastPrice : ""
        }`;
      },
    },
    // {
    //   title: "相关资讯",
    //   dataIndex: "type",
    //   key: "type",
    // },
  ];

  const HandlePress = () =>
    navigation.navigate("Flist", { signal: signal, from: "bi" });

  const TableItem = ({
    columns,
    record,
  }: {
    columns: Column[];
    record: any;
  }) => (
    <ListItem
      containerStyle={{
        paddingHorizontal: 15,
        backgroundColor: "#ffffff",
      }}
    >
      <ListItem.Content>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              margin: 2,
              width: "40%",
              textAlign: "left",
            }}
          >
            <Text style={styles.topLabel}>{record.time}</Text>
          </Text>
          <Text
            style={{
              margin: 2,
              width: "60%",
              textAlign: "right",
            }}
          >
            {/* <TouchableOpacity onPress={HandlePress}>
              <Text
                style={{
                  ...styles.topLabel,
                  borderBottomColor: "#3D3D3E",
                  borderBottomWidth: 1,
                }}
              >
                查看全部历史列表
              </Text>
            </TouchableOpacity> */}
          </Text>
        </View>
        {columns.map((column: Column, index) => (
          <View
            key={column.key}
            style={{
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                lineHeight: 19,
                margin: 2,
                color: "#616F85",
                width: "40%",
                textAlign: "left",
              }}
            >
              {column.title}
            </Text>
            <Text
              style={{
                margin: 2,
                width: "60%",
                textAlign: "right",
              }}
            >
              <Text
                style={{
                  ...styles.topLabel,
                  color: column.color ? column.color(record) : "#323233",
                }}
              >
                {column.render
                  ? column.render(record)
                  : record[column.key] || "-"}
              </Text>
            </Text>
          </View>
        ))}
      </ListItem.Content>
    </ListItem>
  );
  return <TableItem columns={columns} record={signal} />;
}

const styles = StyleSheet.create({
  topLabel: {
    fontSize: 12,
    lineHeight: 19,
    color: "#323233",
  },
});
