import { View, ScrollView, StyleSheet, Image } from "react-native";
import { Text, Card, Button, Icon } from "@rneui/themed";

export default function Notification() {
  return (
    <Card
      containerStyle={{
        backgroundColor: "white",
        borderRadius: 10,
        borderWidth: 0,
        padding:12
      }}
    >
      <View style={styles.container}>
        <Text style={styles.label}>系统通知</Text>
        <Text style={styles.label}>|</Text>
        <Text style={styles.value}>V1.05-上线ETH多信号</Text>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    opacity: 0.8,
    backgroundColor: "#FFFFFF",
    borderRradius: 10,
  },
  label: {
    color: "#0A054F",
    fontSize: 12,
    lineHeight: 20,
    fontWeight: "500",
    marginRight: 8,
  },
  value: {
    color: "#323233",
    fontSize: 12,
    lineHeight: 20,
  },
});
