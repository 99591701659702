import React from "react";
import { View, StyleSheet } from "react-native";

export default function Process({
  process_rate,
  warning,
}: {
  process_rate: number;
  warning?: boolean;
}) {
  return (
    <View style={styles.process_container}>
      <View
        style={{
          width: process_rate > 1 ? 54 : 54 * process_rate,
          height: 6,
          backgroundColor: warning
            ? "#fdcc07"
            : process_rate > 1
            ? "#0ECB81"
            : "#E46158",
          borderRadius: 3,
        }}
      ></View>
    </View>
  );
}

const styles = StyleSheet.create({
  process_container: {
    width: 54,
    height: 6,
    backgroundColor: "#E8E8E8",
    borderRadius: 3,
    margin: 6,
    marginLeft: 0,
  },
});
