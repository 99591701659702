import {
  addDays,
  format,
  getDate,
  isSameDay,
  startOfWeek,
  subDays,
  getMonth,
} from "date-fns";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ArrowButton } from "../components";
type Props = {
  date: Date;
  onChange: (value: Date) => void;
};

const WeekCalendar: React.FC<Props> = ({ date, onChange }) => {
  const [week, setWeek] = useState<WeekDay[]>([]);

  useEffect(() => {
    const weekDays = getWeekDays(date);
    setWeek(weekDays);
  }, [date]);

  const WeekZh = {
    Mon: "周一",
    Tue: "周二",
    Wed: "周三",
    Thu: "周四",
    Fri: "周五",
    Sat: "周六",
    Sun: "周日",
  };

  return (
    <View style={styles.container}>
      <ArrowButton
        size={18}
        left={true}
        onPress={() => {
          const weekDays = getWeekDays(subDays(week[0].date, 7));
          setWeek(weekDays);
        }}
      />
      {week.map((weekDay) => {
        const textStyles: any = [styles.label];
        const zhWeekStyle: any = [styles.weekDayText];
        const touchable: any = [styles.touchable];

        const sameDay = isSameDay(weekDay.date, date);
        if (sameDay) {
          textStyles.push(styles.selectedLabel);
          zhWeekStyle.push(styles.selectedLabel);
          touchable.push(styles.selectedTouchable);
        }

        return (
          <View
            style={styles.weekDayItem}
            key={weekDay.formatted}
          >
            <TouchableOpacity
              onPress={() => onChange(weekDay.date)}
              style={touchable}
            >
              <Text style={zhWeekStyle}>
                {/* @ts-ignore */}
                {WeekZh[weekDay.formatted]}
              </Text>
              <Text style={textStyles}>
                {weekDay.day === 1 ? weekDay.month + "月" : weekDay.day}
              </Text>
            </TouchableOpacity>
          </View>
        );
      })}
      <ArrowButton
        size={18}
        disabled={week?.[week.length - 1]?.date > new Date()}
        onPress={() => {
          const weekDays = getWeekDays(addDays(week[0].date, 7));
          setWeek(weekDays);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: 10,
    backgroundColor: "#FBFBFB",
  },
  weekDayText: {
    color: "gray",
    fontSize: 12,
    letterSpacing: 0,
    textAlign: "center",
  },
  label: {
    fontSize: 11,
    color: "black",
    textAlign: "center",
  },
  selectedLabel: {
    color: "white",
  },
  touchable: {
    borderRadius: 20,
    padding: 7.5,
    height: 40,
    width: 40,
  },
  selectedTouchable: {
    backgroundColor: "#3F4DDC",
  },
  weekDayItem: {
    alignItems: "center",
  },
});

type WeekDay = {
  formatted: string;
  date: Date;
  day: number;
  month?: number;
};

// get week days
export const getWeekDays = (date: Date): WeekDay[] => {
  const start = startOfWeek(date, { weekStartsOn: 1 });

  const final = [];

  for (let i = 0; i < 7; i++) {
    const date = addDays(start, i);
    final.push({
      formatted: format(date, "EEE"),
      date,
      day: getDate(date),
      month: getMonth(date),
    });
  }
  return final;
};

export default WeekCalendar;
