import { StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "rgb(245,247,250)",
    },
    td: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    ftdBox: {
      backgroundColor: "#F7FCFF",
    },
    tdItem: {
      width: "25%",
    },
    tdItemLast: {
      width: "10%",
      alignItems: "center",
      justifyContent: "center",
    },
    topLabel: {
      fontSize: 12,
      lineHeight: 19,
      color: "#323233",
    },
    bottomLabel: {
      fontSize: 10,
      lineHeight: 19,
      color: "#616F85",
    },
  });
  

  export default styles