import React from "react";
import { View, StyleSheet } from "react-native";
import { getStatusBarHeight } from "react-native-status-bar-height";
import { ArrowButton } from "../components";

export default function BackButton({ goBack, left }: any) {
  return (
    <View style={{ ...styles.container, left: left || 20 }}>
      <ArrowButton left={true} size={24} onPress={goBack} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 10 + getStatusBarHeight(),
    left: 20,
  },
  image: {
    width: 24,
    height: 24,
  },
});
